import * as React from "react"
import Layout from "../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"
import './ensembles.css'

const Ensembles = () => {

  return (
    <>
      <Seo title="Ensembles" description="On the ensembles page you can find information about the ensembles that I'm playing in. If you ould like to book one of my ensembles, feel free to contact me via the contact page."/>
      <Layout >
        <section className="main-ensembles">
          
          <div className="top">
              <h2 className="sub-title">Ensembles</h2>
              <p>Pianist Duco Burgers and I founded the Dou Duo in 2013. We perform the greatest hits and lesser-known pieces from the oboe-piano repertoire. We currently play a French program with the Sonatas by Saint-Saëns, Poulenc and Dutilleux.</p>
            </div>
          <div className="bottom">
              <StaticImage 
                src="../../images/5439.jpg"
                alt="Quentin Rychner" 
                placeholder="blurred">
              </StaticImage>
              <StaticImage 
                src="../../images/duco.jpg"
                alt="Duco Burgers" 
                placeholder="blurred">
              </StaticImage>
              <StaticImage 
                src="../../images/5450.jpg"
                alt="Musical ensemble" 
                placeholder="blurred">
              </StaticImage>
            </div>
        </section>
      </Layout>
    </>
  )
}

export default Ensembles
